import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog24/image1.png"
import image2 from "../../images/blog24/image2.jpg"

export default function IsCaffeineBadForUs(){
    return(
        <BlogPostOld
            src={image}
            title={"My Two Cents: Knee Pain - Don’t Just Stop Running"}
            summary={"If you enjoy running and you do it long enough, the occasional sensation of knee pain isn’t abnormal. For some this sensation can come with concern, worry and fear that running is inevitably the culprit."}
            date={"Sept. 27, 2022"}
            author={"Dr. Donald Mull, DC"}
        > <div>
                        <p>If you enjoy running and you do it long enough, the occasional sensation of knee pain isn’t abnormal. For some this sensation can come with concern, worry and fear that running is inevitably the culprit.</p>
            <p>This bias that has been created by your experiences is then confirmed when you become interested enough to ask questions. A poorly informed clinician tells you that running will eventually lead to degenerative changes and wear in tear in their joints. The algorithms now also push quick fixes like pain medications, knee braces with magic copper, CBD creams, etc your way, telling you that you must be fixed and in a hurry.&nbsp;</p>
            <p>Unsure who to trust because the internet is full of conflicting information, you ask close friends and family. You begin to hear the horror stories. A family member shares how failed surgeries eventually led to a complete knee replacement and now he or she can no longer fully bend their knee so they struggle to get down to the ground to play with the kids. Your friend tells you that they had just the same thing you do and the only thing that helped them was to stop running.&nbsp;</p>
            <p>At this point, it is hard to believe that there is any other option than to give up running right? Not so fast. Let’s take a step back for a second. This is a story we hear ALL OF THE TIME so I think it would be helpful to provide some perspective.&nbsp;</p>
            <h2 className={"text-center underline"}><strong>Your knee pain shouldn’t stop you from running</strong></h2>
            <p>We have zero evidence to tell us that running is bad for our knees. In fact, we know that those who recreationally run are less likely to have osteoarthritis than those who are sedentary. Yes you heard that right - running is not only not bad for your knees but it is GOOD for them. Remember, that when our body is stressed it responds by becoming stronger and more resilient. So it is wear and REPAIR not wear and tear.&nbsp;</p>
            <img src={image2} alt={"blog image"}/>
            <h2 className={"text-center underline"}><span style={{textDecoration: 'underline'}}><strong>Beware of False Claims on the internet</strong></span></h2>
            <p>Remember, the internet does an incredible job at two things - confirming a bias and selling products. So if you are browsing about knee pain you will get ads trying to sell you a quick fix to knee pain. Here is the problem with those “quick fixes” - they normally don’t exist. Yet there are plenty of so-called experts who will tell you otherwise.</p>
            <p>Here is what most don’t tell you. What you are feeling is normal. Pain does not mean damage. Maybe you just need to peel back the total mileage by 10-20 percent per week. The dose can be the difference between a cure and poison.&nbsp;</p>
            <h2 className={"text-center underline"}><span style={{textDecoration: 'underline'}}><strong>What can Help your knee pain</strong></span></h2><p>Maybe you can benefit from adding a structured strength training routine 2-3x a week. Increasing muscular strength can not only help to take on the stresses of running but can also increase performance. When running the calf complex alone takes on 5-8x your bodyweight each step. So it would make sense that increasing the muscular strength of each leg would benefit the performance outcomes and possibly benefit the pain you are feeling.</p>
            <p>Or maybe what your family member from the story above didn’t tell you is that he or she is generally sedentary, drinks regularly and has a very stressful job. These unhealthy lifestyle factors play a major role in systemic inflammatory markers. This could have played a larger role into the poor outcomes in their experience but these don’t come to the surface when they share their story with you.&nbsp;</p>
            <h2 className={"text-center underline"}><span style={{textDecoration: 'underline'}}><strong>Knee Pain is Complex</strong></span></h2>
            <p>Moral of the story is, there are SO many factors that play a role in pain. To say you need to give up running because you have pain or even because you have anatomical changes in your knee is BS. If you properly prepare your body for an activity and give it time to adapt positively, you are setting yourself up for success. Here is a little checklist that can be helpful to get you back and running.&nbsp;</p>
            <ul>
                <li>Resistance training 2-3x a week (1-2 exercises for each larger muscle group at 6-8/10 difficulty each session)</li>
                <li>Take 20-50% of your normal volume and start there (run walk if you need to)</li>
                <li>Pain should not be your only guide - if it is tolerable pain, that’s okay.&nbsp;</li>
                <li>Eat healthy&nbsp;</li>
                <li>Drink a lot of water</li>
                <li>Get good sleep</li>
                <li>Manage stress</li>
                <li>Control body fat (especially in the abdomen area)</li>
            </ul>
            <p>If you need more help than this, a strength coach or good clinician (physical therapist or chiropractor) may be helpful. If they cannot help you organize your programming, find one that can and is willing to do so. That’s just my two cents - take it for what it’s worth.&nbsp;</p>
            <p>Sources:</p>
            <p><a href="https://pubmed.ncbi.nlm.nih.gov/28504066/" target="_blank" rel="noopener">Alentorn-Geli E, Samuelsson K, Musahl V, Green CL, Bhandari M, Karlsson J. The Association of Recreational and Competitive Running With Hip and Knee Osteoarthritis: A Systematic Review and Meta-analysis. J Orthop Sports Phys Ther. 2017 Jun;47(6):373-390. doi: 10.2519/jospt.2017.7137. Epub 2017 May 13. PMID: 28504066.</a></p>
        </div></BlogPostOld>

    )
}